import "mouse-follower/dist/mouse-follower.min.css";
import './css/main.css';
import barba from '@barba/core';

import { gsap } from "gsap";

import MouseFollower from "mouse-follower";
MouseFollower.registerGSAP(gsap);


barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        // hamburgerToggle("o");
        vidLoopCheck();
        playerCheck();
        galleryCheck();
        vidHoverCheck();
        homeDirectors(data);
      },
      afterOnce(data) {
      }
    }],
});


function vidLoopCheck() {
    let vids = document.getElementsByClassName('twicVid');
    if (vids.length > 0) {
        import(/* webpackChunkName: "vidHover" */ "./js/components/vidHover.js").then(module => {
            const vidLoopGo = module.default;
        vidLoopGo();
         });
    };
};

function playerCheck() {
    let player = document.getElementsByClassName('customPlayer');
    if (player.length > 0) {
        import(/* webpackChunkName: "player" */ "./js/components/videoPlayer.js").then(module => {
            const playerGo = module.default;
            playerGo();
         });
    }
}

function galleryCheck() {
  let galleries = document.getElementsByClassName('gallery');
  if (galleries.length > 0) {
    import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
      const galleryGo = module.default;
      galleryGo();
  });
};
};


function hamburgerToggle(preset) {
  import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
    const toggle = module.default;
    toggle(preset);
  });
}

let dropdownCloser = document.getElementById('dropdownCloser');
dropdownCloser.addEventListener('click', function() {hamburgerToggle('c')});

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});

function vidHoverCheck() {
  if (window.innerWidth > 760) {
      let cursor = new MouseFollower();
      barba.hooks.before((data) => {
        cursor.destroy();
      })
  };
};

function homeDirectors(data) {
  let footerDirectors = document.getElementById('footerDirectors');
  let footerHome = document.getElementById('footerHome');
  if (data.next.namespace === "home") {
    footerDirectors.classList.add('hidden');
    footerHome.classList.remove('hidden');
    footerDirectors.classList.add('pointer-events-none');
  } else {
    footerDirectors.classList.remove('hidden');
    footerHome.classList.add('hidden');
    footerDirectors.classList.remove('pointer-events-none');
  }
}





barba.hooks.after((data) => {
    vidLoopCheck();
    playerCheck();
    galleryCheck();
    window.scrollTo(0, 0);
    vidHoverCheck();
    homeDirectors(data);
    // menuCloseLinkChecker();
});

barba.hooks.before((data) => {
  hamburgerToggle('c');
})